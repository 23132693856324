import Contact from "../component/utils/contact";
import Footer from "../component/utils/footer";
import Header from "../component/utils/header";

export default function FormComment() {
    return (
      <div>
        <Header/>
        <Contact/>
        <Footer/>
      </div>
    );
  }