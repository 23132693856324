import React from 'react';

const DashboardAnimaux = (props) => {
    

    return (
        <div className="text-3xl text-vertfonce">
            <p>Bienvenue sur le dashboard administrateur</p>
        </div>
    );
};

export default DashboardAnimaux;
