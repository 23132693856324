import Footer from "../component/utils/footer";
import Header from "../component/utils/header";

import Form from "../component/form/comment";

export default function FormCommentPage() {
  return (
    <div>
      <Header/>

      <Form/>

      <Footer/>
    </div>
  );
}