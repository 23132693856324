import Footer from "../component/utils/footer";
import Header from "../component/utils/header";
import Service from "../component/service/serviceContent";

export default function Home() {
    return (
      <div>
        <Header/>

        <Service/>

        <Footer/>
      </div>
    );
  }